import { Injectable } from '@angular/core';
import { DemoSankeyData } from './sankey.model';
import * as _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { KeyValue } from '../../shared/models/keyvalue.model';
import { Formula } from '../../shared/components/formula/formula.component';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SankeyService {

  baseURL: string = "/api/advanced/sankey"

  currentObjectID: number
  sankeyDef: SankeyDef
  currentObjectIDLinks: number
  sankeyLinkData: { [uuid: string]: LinkObject }

  sensors: any

  constructor(private http: HttpClient) {
    this.sankeyDef = new SankeyDef
    this.sankeyLinkData = {}
  }

  findLinkByUUID(uuid: string): LinkObject {
    return this.sankeyLinkData[uuid]
  }

  //Return names of the components denoted by the parent array. If parents is empty, toplevel is returned.
  getComponentNames(parents?: string[]): string[] {
    if (_.isEmpty(parents)) {
      return Object.keys(this.sankeyDef.definition)
    }
    return Object.keys(this.getProcessEntryRef(parents).labels)
  }

  getSankeyDef(objectID: number): Observable<SankeyDef> {
    return this.http.get(`${this.baseURL}/${objectID}/definition`).pipe(
      map((res: any) => {
        return JSON.parse(res.data)
      }),
      catchError(error => {
        if (error.status === 404) {
          console.log('No sankey definition found');
          return of(new SankeyDef);
        }
      })
    );
  }

  saveSankeyDef(objectID: number): Observable<void> {
    let data = { data: this.sankeyDef }
    return this.http.post(`${this.baseURL}/${objectID}/definition`, JSON.stringify(data)).pipe(
      map((res: any) => {
        console.log("Sankey definition saved")
      })
    );
  }

  deleteSankeyDef(objectID: number): Observable<void> {
    return this.http.delete(`${this.baseURL}/${objectID}/definition`).pipe(
      map((res) => {
        this.currentObjectID = undefined
        this.sankeyDef = new SankeyDef
      })
    );
  }

  getSankeyLinkData(objectID: number): Observable<any> {
    return this.http.get(`${this.baseURL}/${objectID}/links`).pipe(
      map((res: any) => {
        return JSON.parse(res.data);
      }),
      catchError(error => {
        if (error.status === 404) {
          console.log('No sankey link data found');
          return of({});
        }
      })
    );
  }

  saveLinks(objectID: number): Observable<void> {
    let data = { data: this.sankeyLinkData }
    return this.http.post(`${this.baseURL}/${objectID}/links`, JSON.stringify(data)).pipe(
      map((res: any) => {
        console.log("Sankey link data saved")
      })
    );
  }

  deleteSankeyLinkData(objectID: number): Observable<void> {
    return this.http.delete(`${this.baseURL}/${objectID}/links`).pipe(
      map((res) => {
        this.currentObjectIDLinks = undefined
        this.sankeyLinkData = {}
      })
    );
  }

  loadDefinition(objectID: number): Observable<void> {
    console.debug("Load sankey def...")
    //Check if the sankey definitions is already loaded
    if (this.currentObjectID == objectID) {
      console.debug(`Sankey def already loaded for ${objectID}.`)
      return of(null)
    }
    console.debug(`Required to load new sankey def data into service for ${objectID}...`)
    return this.getSankeyDef(objectID).pipe(
      map(data => {
        console.debug(`Sankey def loaded for ${objectID}!`)
        this.currentObjectID = objectID
        this.sankeyDef = new SankeyDef
        this.sankeyDef.flows = data.flows
        this.sankeyDef.types = data.types
        Object.keys(data.definition).forEach(k => {
          let v = data.definition[k]
          let p = new ProcessEntry
          p.labels = v.labels //TODO: LOAD ALL SUBPROCESSES CORRECTLY!
          p.links = v.links
          v.keyvalues.forEach(kv => {
            let newKV = new KeyValue(kv.name, kv.formula, kv.rootcauses)
            p.keyvalues.push(newKV)
          });
          this.sankeyDef.definition[k] = p
        });
      })
    )
  }

  loadLinks(objectID: number): Observable<void> {
    console.debug("Load sankey links...")
    //Check if the links is already loaded
    if (this.currentObjectIDLinks == objectID) {
      console.debug(`Sankey links already loaded for ${objectID}.`)
      return of(null)
    }
    console.debug(`Required to load new sankey links into service for ${objectID}...`)
    return this.getSankeyLinkData(objectID).pipe(
      map(data => {
        console.debug(`Sankey links loaded for ${objectID}!`)
        this.currentObjectIDLinks = objectID
        this.sankeyLinkData = {}
        Object.keys(data).forEach(k => {
          let newLinkObj = new LinkObject()
          newLinkObj.flow = data[k].flow
          newLinkObj.source = data[k].source
          newLinkObj.target = data[k].target
          newLinkObj.type = data[k].type

          if (data[k].equations) {
            let energyFormula = new LinkEquation({ show: 'Energy', data: 'energy' })
            energyFormula.formula = data[k].equations.energy.formula
            newLinkObj.equations.energy = energyFormula

            //Only load exergy if existing. Old files might not have the exergy field
            if (data[k].equations.exergy) {
              let exergyFormula = new LinkEquation({ show: 'Exergy', data: 'exergy' })
              exergyFormula.formula = data[k].equations.exergy.formula
              newLinkObj.equations.exergy = exergyFormula
            }

            //Add extra formulas
            if (data[k].equations.extras) {
              data[k].equations.extras.forEach(e => {
                let extraFormula = new LinkEquation({ show: e.name.show, data: e.name.data })
                extraFormula.formula = e.formula
                newLinkObj.equations.extras.push(extraFormula)
              });
            }
          }

          newLinkObj.uuid = k
          this.sankeyLinkData[k] = newLinkObj
        });
      })
    )
  }

  //Return all the energy types available
  getTypes(): string[] {
    return this.sankeyDef.types
  }

  //Add a new energy type
  addType(newType: string) {
    this.sankeyDef.types.push(newType)
  }

  deleteType(type: string) {
    let index = this.sankeyDef.types.indexOf(type)
    if (index > -1) {
      this.sankeyDef.types.splice(index, 1)
    }
  }

  //Return all the available flows
  getFlows(): string[] {
    return this.sankeyDef.flows
  }

  //Adds a new flow
  addFlow(flow: string) {
    this.sankeyDef.flows.push(flow)
  }

  deleteFlow(flow: string) {
    let index = this.sankeyDef.flows.indexOf(flow)
    if (index > -1) {
      this.sankeyDef.flows.splice(index, 1)
    }
  }

  getProcessEntry(parents: string[]): ProcessEntry {
    let process = this.getProcessEntryRef(parents)
    return _.cloneDeep(process)
  }

  getAllProcessesViews() {
    return this.sankeyDef.definition
  }

  //TODO: Needed?
  getProcess(name: string, path: string[]) {
    if (_.isEmpty(path)) {
      path = [name]
    } else {
      path = [...path, name]
    }
    let process = this.sankeyDef.definition[path.shift()]
    path.forEach(e => {
      process = process.labels[e]
    });
    return process
  }

  // Add a new empty process
  addProcess(p: string, path: string[]) {
    if (path.length > 0) {
      let node: ProcessEntry = this.sankeyDef.definition[path.shift()]

      while (path.length) {
        node = node.labels[path.shift()]
      }

      node.labels[p] = new ProcessEntry
    } else {
      this.sankeyDef.definition[p] = new ProcessEntry
    }
  }

  // Delete a process
  deleteProcess(p: string, path: string[]) {
    if (path.length > 0) {
      let node: ProcessEntry = this.sankeyDef.definition[path.shift()]

      while (path.length) {
        node = node.labels[path.shift()]
      }
      delete node.labels[p]
    } else {
      delete this.sankeyDef.definition[p]
    }
  }

  getProcessEntryRef(path: string[]): ProcessEntry {
    let process = this.sankeyDef.definition[path.shift()]
    path.forEach(e => {
      process = process.labels[e]
    });
    return process
  }

}

export class LinkEquation extends Formula {

  name: {
    show: string
    data: string
  }

  constructor(name: { show: string, data: string }) {
    super()
    this.name = name
  }
}

export class LinkObject {
  source: string
  target: string
  type: string
  flow: string[]
  uuid: string

  equations: {
    energy: LinkEquation
    exergy: LinkEquation
    extras: LinkEquation[]
  }

  constructor(obj?: any) {
    this.flow = []
    this.uuid = uuid()
    this.equations = {
      energy: new LinkEquation({ show: 'Energy', data: 'energy' }),
      exergy: new LinkEquation({ show: 'Exergy', data: 'exergy' }),
      extras: []
    }
    if (obj) {
      for (var key in obj) {
        this[key] = obj[key]
      }
    }

  }

}

export class SankeyDef {
  definition: { [process: string]: ProcessEntry }
  types: string[]
  flows: string[]
  constructor() {
    this.definition = {}
    this.types = []
    this.flows = []
  }
}

export class ProcessEntry {
  labels: { [process: string]: ProcessEntry }
  links: string[]
  keyvalues: KeyValue[]

  constructor() {
    this.labels = {}
    this.links = []
    this.keyvalues = []
  }
}
