import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SankeyService, ProcessEntry, LinkObject, LinkEquation } from '../sankey.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle, faEdit } from '@fortawesome/free-regular-svg-icons';
import * as _ from 'lodash';
import { NgbModal, NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { APIObjectDetailed, CompanyObjectService } from '../../../shared/services/company-object.service';
import { forkJoin } from 'rxjs';


@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    currentObject: APIObjectDetailed;
    faQuestionCircle = faQuestionCircle
    faTimes = faTimes;
    faEdit = faEdit;
    processDropDown = []
    linkProcessDropDown = []
    typesDropDown = []
    flowsDropDown = []
    keyFigureLinks = []
    editFormula = {}
    editLink: LinkObject = new LinkObject

    selectedComponent: any

    equationNames: any

    history: string[] = []
    @ViewChild('unsavedModal', { static: true }) unsavedModal: ElementRef;

    //Information about the current view shown
    currentView: ProcessEntry
    currentViewCopy: ProcessEntry
    currentViewLinkData: LinkObject[] = []
    currentViewName: string

    linkChangesAdd: string[] = []
    linkChangesDelete: string[] = []

    equationTrans: any = {}

    updateEquationTranslates() {
        this.equationNames = [
            { show: this.equationTrans.power, data: "power" },
            { show: this.equationTrans.pressure, data: "pressure" },
            { show: this.equationTrans.temperature, data: "temperature" },
            { show: this.equationTrans.massflow, data: "massflow" },
            { show: this.equationTrans.volumeflow, data: "volumeflow" },
            { show: this.equationTrans.tonne, data: "tonne" },
            { show: this.equationTrans.dry_solids, data: "dry_solids" },
            { show: this.equationTrans.moisture, data: "moisture" },
            { show: this.equationTrans.cubic_meter, data: "cubic_meter" }
        ]
    }

    constructor(
        private _sankeyService: SankeyService,
        private _modalService: NgbModal,
        private _objectService: CompanyObjectService,
        private translate: TranslateService
    ) {
        translate.stream(['general.equations']).subscribe(res => {
            this.equationTrans = res['general.equations']
            this.updateEquationTranslates()
        });
    }

    ngOnInit() {
        this.currentObject = this._objectService.currentObject()
        forkJoin([
            this._sankeyService.loadDefinition(this.currentObject.id),
            this._sankeyService.loadLinks(this.currentObject.id)
        ]).subscribe(
            () => {
                this.processDropDown = this._sankeyService.getComponentNames()
                this.linkProcessDropDown = ['input', 'output', ...this.processDropDown]
                this.typesDropDown = this._sankeyService.getTypes()
                this.flowsDropDown = this._sankeyService.getFlows()
                this.getKeyValueLinks()
            }
        )
    }

    addLink(direction: string) {
        let newLink = new LinkObject()
        newLink[direction] = this.currentViewName
        this.currentView.links.push(newLink.uuid)
        this.currentViewLinkData.push(newLink)
        this.linkChangesAdd.push(newLink.uuid)
    }

    deleteLink(modal, uuid) {
        this._modalService.open(modal, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            if (result == "delete") {
                let i = this.currentView.links.indexOf(uuid)
                this.currentView.links.splice(i, 1)
                i = _.findIndex(this.currentViewLinkData, (e) => { return e.uuid == uuid; });
                this.currentViewLinkData.splice(i, 1)

                i = _.findIndex(this.linkChangesAdd, (e) => { return e == uuid; })

                if (i > -1) {
                    this.linkChangesAdd.splice(i, 1)
                } else {
                    this.linkChangesDelete.push(uuid)
                }
            }
        },
            (result) => {
                //If closed via clicking backdrop or using esc key.
            });
    }

    onProcessChange(next) {
        if (next) {
            /* if (this.currentCopy && !_.isEqual(this.currentCopy, this.currentView)) {
              console.log("Unsaved changes detected")
              //this.openUnsavedModal(this.unsavedModal)
            } */
            this.loadView([...this.history, next])
            this.currentViewName = next
        }
    }

    toSubprocess(dest: string) {
        this.history.push(this.currentViewName)
        this.loadView([...this.history, dest])
        this.processDropDown = this._sankeyService.getComponentNames([...this.history])
        this.linkProcessDropDown = ['input', 'output', ...this.processDropDown]
        this.getKeyValueLinks()
        this.selectedComponent = dest //TODO: Needed?
        this.currentViewName = dest
    }

    back(dest: string) {
        this.loadView([...this.history.slice(0, this.history.indexOf(dest) + 1)])
        this.history = this.history.slice(0, this.history.indexOf(dest))
        this.processDropDown = this._sankeyService.getComponentNames([...this.history])
        this.linkProcessDropDown = ['input', 'output', ...this.processDropDown]
        this.getKeyValueLinks()
        this.selectedComponent = dest //TODO: Needed?
        this.currentViewName = dest
    }

    //Saves the data of the current process at the selected time.
    save() {
        //Delete the link with the given uuid. Also removes all references to the link.
        //Every datapoint for the link will also be removed.
        this.linkChangesDelete.forEach(uuid => {
            let l = this._sankeyService.findLinkByUUID(uuid)
            if (l.source != "input") {
                let sourceProccessRef = this._sankeyService.getProcessEntryRef([...this.history, l.source])
                let i = _.findIndex(sourceProccessRef.links, (e) => { return e == uuid; })
                sourceProccessRef.links.splice(i, 1)
            }
            if (l.target != "output") {
                let targetProcessRef = this._sankeyService.getProcessEntryRef([...this.history, l.target])
                let i = _.findIndex(targetProcessRef.links, (e) => { return e == uuid; })
                targetProcessRef.links.splice(i, 1)
            }
            delete this._sankeyService.sankeyLinkData[uuid]
        });

        //Add new links created in the current view.
        this.linkChangesAdd.forEach(uuid => {
            let newLink = _.find(this.currentViewLinkData, (e) => { return e.uuid == uuid; })
            let newLinkCopy = _.cloneDeep(newLink)

            //Check if the link has source, target and type set. This is required for link to be saved.
            if (this.isValid(newLinkCopy)) {
                if (newLinkCopy.source != "input" && newLinkCopy.source != "output") {
                    let sourceProccessRef = this._sankeyService.getProcessEntryRef([...this.history, newLinkCopy.source])
                    sourceProccessRef.links.push(uuid)
                }
                if (newLinkCopy.target != "input" && newLinkCopy.target != "output") {
                    let targetProcessRef = this._sankeyService.getProcessEntryRef([...this.history, newLinkCopy.target])
                    targetProcessRef.links.push(uuid)
                }
                this._sankeyService.sankeyLinkData[uuid] = newLinkCopy
            }
        });

        //Save the data from all the already existing links. There might have been changes before the save event.
        let editUUIDS = _.difference(this.currentView.links, this.linkChangesAdd);
        editUUIDS.forEach(uuid => {
            let changedLink = _.find(this.currentViewLinkData, (e) => { return e.uuid == uuid; })
            let savedLink = this._sankeyService.findLinkByUUID(uuid)
            savedLink.source = changedLink.source
            savedLink.target = changedLink.target
            savedLink.type = changedLink.type
            savedLink.flow = changedLink.flow
            savedLink.equations = changedLink.equations
        });

        //Clear the registered changes now that they are saved.
        this.linkChangesAdd = []
        this.linkChangesDelete = []

        //Save changes that might have been done to keyvalues/rootcauses.
        let p = this._sankeyService.getProcessEntryRef([...this.history, this.currentViewName])
        p.keyvalues = _.cloneDeep(this.currentView.keyvalues)

        //Save the sankey data
        this._sankeyService.saveSankeyDef(this.currentObject.id).subscribe() //TODO: Handle subscribe
        this._sankeyService.saveLinks(this.currentObject.id).subscribe() //TODO: Handle subscribe
    }

    loadView(path: string[]) {
        this.currentView = this._sankeyService.getProcessEntry(path)
        this.currentViewCopy = _.cloneDeep(this.currentView)
        this.loadLinks()
    }

    loadLinks() {
        this.currentViewLinkData = []
        this.currentView.links.forEach(uuid => {
            let l = this._sankeyService.findLinkByUUID(uuid)
            this.currentViewLinkData.push(_.cloneDeep(l))
        });
    }

    hasSubprocesses() {
        return Object.keys(this.currentView.labels).length > 0
    }

    getKeyValueLinks() {
        let res = []
        this.processDropDown.forEach(process => {
            let p = this._sankeyService.getProcessEntry([...this.history, process])
            p.links.forEach(uuid => {
                let l = this._sankeyService.findLinkByUUID(uuid)
                let direction: string
                if (l.target == process) {
                    direction = "IN"
                } else {
                    direction = "OUT"
                }
                res.push({ process: process, show: l.type + " - " + direction, uuid: uuid })
            })
        });
        this.keyFigureLinks = res
    }

    isValid(l: LinkObject): boolean {
        return !_.isEmpty(l.source) &&
            !_.isEmpty(l.target) &&
            !_.isEmpty(l.type)
    }

    openUnsavedModal(content) {
        this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        })
        //   this.closeResult = `Closed with: ${result}`;
        // }, (reason) => {
        //   this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        // });
    }

    // private getDismissReason(reason: any): string {
    //   if (reason === ModalDismissReasons.ESC) {
    //     return 'by pressing ESC';
    //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
    //     return 'by clicking on a backdrop';
    //   } else {
    //     return  `with: ${reason}`;
    //   }
    // }

    //FUNCTIONS TO THE SENSOR MODAL
    editSensors(modal, link: LinkObject) {
        this.editLink = link
        this._modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static'
        }).result.then((result) => {
            console.log(result)
        },
            (result) => {
                console.log(result)
            });
    }

    beforeTabChange($event: NgbTabChangeEvent) {
        if ($event.nextId === 'formula-modal-add-tab') {
            this.editLink.equations.extras.push(new LinkEquation({ show: 'New equation', data: '' }))
            $event.preventDefault();
        }
    }

    removeEquation(index: number) {
        this.editLink.equations.extras.splice(index, 1)
    }


}



