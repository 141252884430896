import { Injectable } from '@angular/core';
import { Formula, FormulaEntry } from '../components/formula/formula.component';
import { SankeyService } from '../../components/sankey/sankey.service';
import { DateSpan } from '../components/date-span/date-span.component';
import { SensorService } from './sensor.service';

@Injectable({
  providedIn: 'root'
})
export class FormulaService {

  constructor(
    private _sensorService : SensorService,
    private _sankeyService: SankeyService,
  ) {}

  private operatorMap = {
    "ln(" : "Math.log("
  }

  private getEquationValue(e: FormulaEntry, date: Date): number {
    let l = this._sankeyService.findLinkByUUID(e.linkID)
    if(e.equationName == "energy" || e.equationName == "exergy") {
      return this.evaluate(l.equations[e.equationName], date)
    }

    for (let eq of l.equations.extras) {
      if(eq.name.data == e.equationName) {
        return this.evaluate(eq, date)
      }
    }

    return 0 //TODO: What happens if not found?
  }

  private getSensorValue(e: FormulaEntry, date: Date): number {
    return this._sensorService.getSensorValue(e.sensorID, date)
  }
  private getConstantValue(e: FormulaEntry): number {
    return e.constantValue
  }
  private getOperatorValue(e: FormulaEntry): string {
    if(this.operatorMap[e.operator]) {
      return this.operatorMap[e.operator]
    }
    return e.operator
  }

  private isEquation(e: FormulaEntry): boolean {
    return e.equation
  }
  private isSensor(e: FormulaEntry): boolean {
    return e.sensor
  }
  private isConstant(e: FormulaEntry): boolean {
    return e.constant
  }
  private isOperator(e: FormulaEntry): boolean {
    return e.isOperator
  }

  sum(formula: Formula, span: DateSpan) : number {
    let sum = 0
    let nextDate = new Date(span.from)
    //Find all macthing timestamps
    while (nextDate <= span.to) {
      sum += this.evaluate(formula, nextDate)
      nextDate.setDate(nextDate.getDate() + 1)
    }
    return sum
  }

  avg(formula: Formula, span: DateSpan) : number {
    let nrOfDays = span.nrOfDays()
    let value = this.sum(formula, span)
    return value/nrOfDays
  }



  evaluate(formula: Formula, date: Date): number {
    let expression = ""
    //TODO: THIS IS BECAUSE THINGS NOT LOADED CORRECT FROM LOCAL STORAGE
    let entries = formula.formula

    entries.forEach((e, i) => {
      if (this.isConstant(e)) {
        expression += this.getConstantValue(e)
      } else if (this.isSensor(e)) {
        expression += this.getSensorValue(e, date)
      } else if (this.isEquation(e)) {
        expression += this.getEquationValue(e, date)
      } else if (this.isOperator(e)) {
        expression += this.getOperatorValue(e)
      }
    });
    console.log("Evaling:", expression)
    console.log(eval(expression))
    return eval(expression)
  }

}
