import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { KeyValue } from '../../../../shared/models/keyvalue.model';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ProcessEntry } from '../../sankey.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-keyvalentry',
  templateUrl: './keyvalentry.component.html',
  styleUrls: ['./keyvalentry.component.scss']
})
export class KeyvalentryComponent implements OnInit {
  faTimes = faTimes;
  currentKeyValueRef : KeyValue

  equationNames = []

  @Input('currentView')
  currentView: ProcessEntry;

  @Input('linkList')
  linkList: any = []

  equationTrans: any = {}

  updateEquationTranslates() {
    this.equationNames = [
      { show: this.equationTrans.energy, data: "energy" },
      { show: this.equationTrans.exergy, data: "exergy" },
      { show: this.equationTrans.power, data: "power" },
      { show: this.equationTrans.pressure, data: "pressure" },
      { show: this.equationTrans.temperature, data: "temperature" },
      { show: this.equationTrans.massflow, data: "massflow" },
      { show: this.equationTrans.volumeflow, data: "volumeflow" },
      { show: this.equationTrans.tonne, data: "tonne" },
      { show: this.equationTrans.dry_solids, data: "dry_solids"},
      { show: this.equationTrans.moisture, data: "moisture"},
      { show: this.equationTrans.cubic_meter, data: "cubic_meter"}
    ]
  }

  constructor(
    private translate: TranslateService
  ) {
    translate.stream(['general.equations']).subscribe(res => {
      this.equationTrans = res['general.equations']
      this.updateEquationTranslates()
    });
  }

  ngOnInit() {
    console.log("Keyval:",this.currentView)
  }

  setCurrentKeyValue(index: number) {
    this.currentKeyValueRef = this.currentView.keyvalues[index]
  }

  newKeyValue() {
    this.currentView.keyvalues.push(new KeyValue("New keyvalue", [], []))
  }

  removeKeyValue(k: KeyValue) {
    let input = new KeyValue(k.name, k.formula, k.rootcauses)
    _.remove(this.currentView.keyvalues, (e: KeyValue) => {
      return _.isEqual(e, k)
    })
    if (_.isEqual(this.currentKeyValueRef, input)) {
      this.currentKeyValueRef = undefined
    }
  }

}
