<div class="row" style="justify-content: space-between;">
  <div id="breadcrumb">
    <div *ngFor="let h of history; let last = last">
      <span class="link" (click)="back(h)">{{h}}</span>
      <span *ngIf="!last" class="separator">/</span>
    </div>
  </div>

  <div class="d-flex">
    <div class="calendar-wrapper">
      <app-date-span (change)="changeDateSpan($event)" allowRange="true"></app-date-span>
    </div>

    <div>
      <ng-select [items]="graphView" [clearable]="false" bindLabel="show" bindValue="data"
        [(ngModel)]="selectedGraphView" (change)="changeGraphView()">
      </ng-select>
    </div>
  </div>

  <div>
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-outline-primary btn-sm" id="dataActionsDropdown" ngbDropdownToggle>
        {{ 'view.sites.advanced.sankey.data_actions' | translate }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dataActionsDropdown">
        <app-file-upload></app-file-upload>
        <div ngbDropdownItem>
          <button class="btn btn-danger btn-sm" (click)="openClearModal(clearDataModal)">
            {{ 'view.sites.advanced.sankey.clear_data' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="main-view">
    <div *ngIf="invalidSankey">
      {{ 'view.sites.advanced.sankey.invalid' | translate }}
    </div>
    <div #sankey id="sankey">
      <svg id="sankey-svg" width="100%" height="50vh"></svg>
    </div>
    <div class="row">
      <div class="col-md-3">
        <h5>{{ 'view.sites.advanced.sankey.kpi' | translate }}</h5>
        <div id="keyvalue-list">
          <div class="row hr-row" *ngIf="currentKeyValues.self.length>0">
            <div class="col left-hr">
              <hr>
            </div>
            <div class="col-auto hr-text">{{ 'view.sites.advanced.sankey.in-process' | translate }}</div>
            <div class="col right-hr">
              <hr>
            </div>
          </div>
          <div class="keyvalue-entry" (click)="selectKeyValue(k)"
            *ngFor="let k of currentKeyValues.self; let i = index;">
            <span class="link">{{k.keyvalue.name}}</span>
            <span class="link float-right">{{k.value | number : '1.2-2'}}</span>
          </div>
          <div class="row hr-row" *ngIf="currentKeyValues.sub.length>0">
            <div class="col left-hr">
              <hr>
            </div>
            <div class="col-auto hr-text">{{ 'view.sites.advanced.sankey.in-subprocess' | translate }}</div>
            <div class="col right-hr">
              <hr>
            </div>
          </div>
          <div class=keyvalue-entry (click)="selectKeyValue(k)" *ngFor="let k of currentKeyValues.sub">
            <span class="badge badge-primary">{{k.process}}</span>
            <span class="link">{{k.keyvalue.name}}</span>
            <span class="link float-right">{{k.value | number : '1.2-2'}}</span>
          </div>
        </div>
      </div>

      <div class="col-md-6 pl-5">
        <h5>{{ 'view.sites.advanced.sankey.rootcauses' | translate }}</h5>
        <div *ngIf="!selectedKeyValue">
          {{ 'view.sites.advanced.sankey.select-kv' | translate }}
        </div>
        <div *ngIf="selectedKeyValue">
          <ngb-accordion [closeOthers]="true">
            <ngb-panel *ngFor="let r of selectedKeyValue.rootcauses">
              <ng-template ngbPanelHeader>
                <div class="d-flex align-items-center justify-content-between">
                  <button ngbPanelToggle class="btn btn-link p-0">{{r.rootcause.name}}</button>
                  <span *ngIf="r.value == -1">-</span>
                  <span *ngIf="r.value != -1">{{r.value | number : '1.2-2'}}</span>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row">
                  <div class="col-md-4 bold">{{ 'sankey.description' | translate }}</div>
                  <div class="col-md-8">{{r.rootcause.desc}}</div>
                </div>
                <div class="row">
                  <div class="col-md-4 bold">{{ 'sankey.impact' | translate }}</div>
                  <div class="col-md-8">{{r.rootcause.impact}} %</div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #clearDataModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Clear data?</h4>
  </div>
  <div class="modal-body">
    <p class="font-weight-bold">ALL sankey data for the company object will be deleted.</p>
    <p>Are you sure you want to clear the data?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="clearAllData(modal)">Clear</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('cancel')">Cancel</button>
  </div>
</ng-template>
